function isNotDate(value) {
    return typeof value !== 'object' || !(value instanceof Date);
}
export const RemoveNullValues = (obj) => {
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
            if (!(value instanceof Date)) {
                RemoveNullValues(value);
                if (Object.keys(value).length === 0 && !Array.isArray(value)) {
                    delete obj[key];
                }
            }
        }
        else if (value === 0 ||
            value === '' ||
            value === null ||
            value === undefined ||
            Number.isNaN(value) ||
            isNotDate(new Date(value))) {
            delete obj[key];
        }
    });
    return obj;
};
