export function removeDuplicates(arr) {
    return arr?.filter((item, index) => arr?.indexOf(item) === index);
}
export function removeDuplicatesWithSameId(array) {
    const seenIds = new Set();
    return array.filter((item) => {
        const id = item?.id;
        if (!seenIds.has(id)) {
            seenIds.add(id);
            return true;
        }
        return false;
    });
}
